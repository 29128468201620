<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="lg"
  >
    <template #title>
      {{modalTitle}}
    </template>
    <template #body>
      <user-quote-wizard
        :request-type="requestType"
        :request-product="requestProduct"
        :product-description="productDescription"
        :entity-type="entityType"
        :modal-id="id"
        :has-subject="hasSubjectDataSet"
        :profile-entity="profileEntity"
        :quote-info="quoteInfo"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

const UserQuoteWizard = () => import('../wizards/UserQuoteWizard.vue');
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'QuoteModal',
  components: { UserQuoteWizard, ModalWrapper },
  props: {
    id: String,
    requestType: String,
    requestProduct: String,
    productDescription: String,
    entityType: String,
    profileEntity: Object,
    quoteInfo: String,
  },
  computed: {
    modalTitle() {
      let titleVar = 'Richiesta informazioni';
      if (isNotEmpty(this.productDescription)) titleVar = `Richiesta informazioni ${this.productDescription}`;
      return titleVar;
    },
    hasSubjectDataSet() {
      return isNotEmpty(this.profileEntity);
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
